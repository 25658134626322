import { createColumnHelper, VisibilityState } from "@tanstack/react-table";
import { IAPIPreShootReport } from "api/useGetSales";
import { TInventoryOptionValue } from "constants/common";
import TAggregatorColumnPDF from "types/TAggregatorColumnPDF";
import TAggregatorColumnXLS from "types/TAggregatorColumnXLS";
// import formatCellVehicle from "utils/formatCellVehicle";

export const columnHelper = createColumnHelper<IAPIPreShootReport>();

export const getColumns = (inventory: TInventoryOptionValue) => [
  columnHelper.accessor("vin", {
    cell: (info) => info.getValue() ?? '-',
    header: 'VIN',
    id: "vin",
    // default to unchecked
  }),
  columnHelper.accessor((row) => row.stock_number, {
    id: "stock_number",
    cell: (info) => info.getValue() ?? '-',
    enableSorting: false,
    header: 'Stock #'
  }),
  columnHelper.accessor('vehicle', {
    id: "vehicle",
    cell: (info) => info.getValue(),
    header: 'Vehicle'
  }),
  columnHelper.accessor((row) => row.price, {
    id: "price",
    cell: (info) => info.getValue(),
    header: 'Price'
  }),
  columnHelper.accessor((row) => row.dol, {
    id: "dol",
    cell: (info) => info.getValue(),
    header: 'DOL'
  }),
  columnHelper.accessor((row) => row.photos, {
    id: "photos_count",
    cell: (info) => info.getValue(),
    header: 'Images'
  }),
  columnHelper.accessor((row) => row.total_vdps, {
    id: "total_vdps",
    cell: (info) => info.getValue(),
    header: 'Total VDPs'
  }),
];

export const getPdfColumns = (
  inventory: TInventoryOptionValue,
  columnVisibility: VisibilityState
): TAggregatorColumnPDF<IAPIPreShootReport>[] => {
  const columns: TAggregatorColumnPDF<IAPIPreShootReport>[] = [
    {
      label: "VIN",
      key: "vin",
      func(value): any {
        return value || "-";
      }
    },
    {
      label: "Stock #",
      key: "stock_number",
      func(value): any {
        return value || "-";
      }
    },
    {
      label: "Vehicle",
      key: "vehicle",
      // func: (value, row) => (row ? formatCellVehicle(row) : "-"),
    },
    {
      label: "Price",
      key: "price",
      // func: (value) => `$${Number(value).toLocaleString()}`,
    },
    {
      label: "DOL",
      key: "dol",
    },
    {
      label: "Images",
      key: "photos",
    },
    {
      label: "Total VDPs",
      key: "total_vdps",
    },
  ];

  return columns.filter(column => columnVisibility[column.key] !== false); 
};

export const getXLSColumns = (
  inventory: TInventoryOptionValue,
  columnVisibility: VisibilityState
): TAggregatorColumnXLS<IAPIPreShootReport>[] => {
  const columns: TAggregatorColumnXLS<IAPIPreShootReport>[] = [
    {
      label: "VIN",
      key: "vin",
      func(value): any {
        return value || "-";
      }
    },
    {
      label: "Stock #",
      key: "stock_number",
      func(value): any {
        return value || "-";
      }
    },
    {
      label: "Vehicle",
      key: "vehicle",
      // func: (value, row) => (row ? formatCellVehicle(row) : "-"),
    },
    {
      label: "Price",
      key: "price",
      // func: (value) => `$${Number(value).toLocaleString()}`,
    },
    {
      label: "DOL",
      key: "dol",
    },
    {
      label: "Images",
      key: "photos",
    },
    {
      label: "Total VDPs",
      key: "total_vdps",
    },
  ];

  return columns.filter(column => columnVisibility[column.key] !== false); 
};

export const defaultOffset = 0;
