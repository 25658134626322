import { useQuery } from "@tanstack/react-query";
import EDaysOnLot from "types/APIDaysOnLot";
import {
  IDateRanges,
  IDealership,
  IPagination,
  ISearch,
  TInventory,
  TPagination,
} from "types/APIParameters";
import {
  EProviderInitials,
  IPairProviderRatings,
} from "types/APIProviderRanks";
import { IAPIDefaultResults, IAPIResults } from "types/APIResults";
import fetchData from "utils/fetchData";

export enum EModules {
  AdvancedReports = "advanced-reports",
  AdvancedReportsV2 = "v2/advanced-reports",
  GetVDPByVehicle = "vdps-by-vehicle",
  GetVDPByBodyStyle = "vdps-by-bodystyle",
  // VDPBoost = "vdp-boost",
}

export enum EOrderingReports {
  DaysOnLot = "-dol",
  LeastVDPs = "total_vdps",
  MostVDPs = "-total_vdps",
  MostLeads = "-total_leads",
  VDPBoost = "vdp_boost",
}

export type TAdvancedReportOrderKeys =
  | "carfax_pulls"
  | "-carfax_pulls"
  | "vehicle_year"
  | "-vehicle_year"
  | "stock_number"
  | "-stock_number"
  | "price"
  | "-price"
  | "photos"
  | "-photos"
  | "total_leads"
  | "-total_leads"
  | "total_vdps"
  | "-total_vdps"
  | "leads_vdps"
  | "-leads_vdps"
  | "dol"
  | "-dol"
  | "default_percentage_to_market"
  | "-default_percentage_to_market"
  | "cars_com_deal_rating"
  | "-cars_com_deal_rating"
  | "cargurus_deal_rating"
  | "-cargurus_deal_rating"
  | "autotrader_deal_rating"
  | "-autotrader_deal_rating"
  | "carfax_deal_rating"
  | "-carfax_deal_rating"
  | "website_deal_rating"
  | "-website_deal_rating"
  | "";

// v2/advanced-reports
type TParams = {} & TPagination & IDealership;

type TAdvancedReportsParamsV2 = {

} & TParams & IDateRanges & ISearch & TInventory;

export type TAdvancedReportResultsV2 = {
  vin:                          string;
  vehicle:                      string;
  vehicle_year:                 number;
  vehicle_make:                 string;
  vehicle_model:                string;
  vehicle_trim:                 string;
  stock_number:                 string;
  price:                        string;
  description:                  string;
  photos:                       number;
  dol:                          number;
  total_vdps:                   number;
  total_leads:                  number;
  carfax_pulls:                 number;
  leads_vdps:                   string;
  default_percentage_to_market: number;
  price_to_market:              number;
  last_price_change:            Date;
  cgr_deal_rating:              string;
  atc_deal_rating:              string;
  ccm_deal_rating:              string;
  cfx_deal_rating:              string;
}

export type TAPIAdvancedReportsV2 = IAPIDefaultResults<TAdvancedReportResultsV2>;

// end of v2/advanced-reports

interface IParams extends IPagination, IDealership {}

interface IGetVDPByVehicleParams extends IParams, TInventory {}

interface IAdvancedReportsParams extends IDateRanges, IParams, ISearch {
  /** @requires if order == -dol */
  dol_max?: number;
  /** @requires if order == -dol */
  dol_min?: number;
  dol_range: EDaysOnLot;
  order: EOrderingReports;
  ordering: TAdvancedReportOrderKeys;
  source: EProviderInitials[];
}

interface IGetVDPByBodyStyleParams extends IDateRanges, IParams, TInventory {}

export interface IAPIVDPByVehicle {
  stock_number: string;
  total_vdps: number;
  vehicle_make: string;
  vehicle_model: string;
  vehicle_trim: string;
  vehicle_year: string;
}

// temporary field\
interface IRanks {
  CarGurusRank: string;
  CarsRank: string;
  AutotraderRank: string;
}

export interface IAPIAdvancedReports {
  carfax_pulls: IRanks;
  deal_ratings: IPairProviderRatings[];
  default_percentage_to_market: number;
  dol: number;
  last_price_chance: number;
  leads_vdps: number;
  photos: number;
  price: number;
  vin: string;
  stock_number: string;
  total_leads: number;
  total_vdps: number;
  vehicle_make: string;
  vehicle_model: string;
  vehicle_trim: string;
  vehicle_year: number;
}

export interface IAPIGetVDPByBodyStyle {
  body_style_label: string;
  total_vdps: number;
}

interface IOptions<T extends string> {
  module: EModules;
  params?: T extends EModules.GetVDPByVehicle
    ? IGetVDPByVehicleParams
    : T extends EModules.AdvancedReports
    ? IAdvancedReportsParams
    : T extends EModules.AdvancedReportsV2
    ? TAdvancedReportsParamsV2
    : T extends EModules.GetVDPByBodyStyle
    ? IGetVDPByBodyStyleParams
    : never;
  queryKeys?: any[];
}

type FetchDataReturnType<T extends IOptions<T>["module"]> =
  T extends EModules.GetVDPByVehicle
    ? IAPIVDPByVehicle[]
    : T extends EModules.AdvancedReports
    ? IAPIResults<IAPIAdvancedReports[]>
    : T extends EModules.AdvancedReportsV2
    ? IAPIDefaultResults<TAdvancedReportResultsV2[]>
    : T extends EModules.GetVDPByBodyStyle
    ? IAPIGetVDPByBodyStyle[]
    : never;

const useGetStatistics = <T extends IOptions<T>["module"]>({
  module,
  params,
  queryKeys,
}: IOptions<T>) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryFn: getStatisticsAsync<T>({ module, params }),
    queryKey: [
      "getStatistics",
      ...Object.values(params || {}),
      ...(queryKeys || []),
    ],
    keepPreviousData: true,
  });
};

export const getStatisticsAsync = <T extends IOptions<T>["module"]>({
  module,
  params,
}: IOptions<T>) =>
  fetchData<FetchDataReturnType<T>>({
    isAuth: true,
    method: "GET",
    params,
    url: `${process.env.REACT_APP_API_URL || ""}api/statistics${
      module ? `/${module}` : ""
    }`,
  });

export default useGetStatistics;
