import { createColumnHelper, VisibilityState } from "@tanstack/react-table";
import { TTipReportV2Results } from "api/useGetSales";
import Badge from "components/Badge";
import { TInventoryOptionValue } from "constants/common";
import { EAPIProviderRanks, EAPIProviderRanksReverse, EProviderInitials } from "types/APIProviderRanks";
import TAggregatorColumnPDF from "types/TAggregatorColumnPDF";
import TAggregatorColumnXLS from "types/TAggregatorColumnXLS";
import getProviderRank from "utils/getProviderRank";
import { defaultValueToDash } from "utils/helper";

const columnHelper = createColumnHelper<TTipReportV2Results>();

export const getColumns = (inventory: TInventoryOptionValue) => [
    columnHelper.group({
        header: "Vehicle Info",
        id: "vehicleInfo",
        columns: [
            columnHelper.accessor("vin", {
                cell: (info) => defaultValueToDash(info.getValue()),
                header: 'VIN',
                id: "vin",
                // default to unchecked
            }),
            columnHelper.accessor("stock_number", {
                cell: (info) => defaultValueToDash(info.getValue()),
                header: "Stock #",
                size: 200,
            }),
            columnHelper.accessor("vehicle", {
                id: "vehicle",
                cell: (info) => defaultValueToDash(info.getValue()),
                header: "Vehicle",
                size: 350,
            }),
            columnHelper.accessor("age", {
                header: "Age",
                size: 50,
                enableResizing: true
            }),
        ],
    }),
    {
        header: "CarGurus",
        filterColumnId: EProviderInitials.CarGurus,
        // columns: getProviderColumns("cargurus", "cargurus"),
        columns: [
            columnHelper.accessor("cgr_price", {
                cell: (info) => defaultValueToDash(info.getValue()),
                header: "Price",
                id: `cgr_price`,
            }),
            columnHelper.accessor("cgr_deal_rating", {
                cell: (info) => {
                    const { color, icon, label } = getProviderRank(
                        info.getValue() as EAPIProviderRanks
                    );
                    return <Badge color={color} icon={icon} label={label} size="sm" />;
                },
                header: "Deal Rating",
                id: `cgr_deal_rating`,
                size: 250,
            }),
            columnHelper.accessor("cgr_new_deal_rating", {
                cell: (info) => {
                    const { color, icon, label } = getProviderRank(
                        info.getValue() as EAPIProviderRanks
                    );
                    return <Badge color={color} icon={icon} label={label} size="sm" />;
                },
                header: "New Deal Rating",
                id: `cgr_new_deal_rating`,
                size: 250,
            }),
            columnHelper.accessor("cgr_new_price", {
                cell: (info) => defaultValueToDash(info.getValue()),
                header: "New Price",
                id: `cgr_new_price`,
            }),
            columnHelper.accessor("cgr_price_change", {
                cell: (info) => defaultValueToDash(info.getValue()),
                header: "Price Change",
                id: `cgr_price_change`,
            }),
        ],
    },
    {
        header: "Autotrader",
        filterColumnId: EProviderInitials.Autotrader,
        columns: [
            columnHelper.accessor("atc_deal_rating", {
                cell: (info) => {
                    
                    if (!info.getValue()) {
                        return '-';
                    }
                    
                    const { color, icon, label } = getProviderRank(
                        info.getValue() as EAPIProviderRanks
                    );
                    return <Badge color={color} icon={icon} label={label} size="sm" />;
                },
                header: "Deal Rating",
                id: `atc_deal_rating`,
                size: 250,
            }),
            columnHelper.accessor("atc_price", {
                cell: (info) => defaultValueToDash(info.getValue()),
                header: "Price",
                id: `atc_price`,
            }),
            columnHelper.accessor("atc_new_deal_rating", {
                cell: (info) => {

                    if (!info.getValue()) {
                        return '-';
                    }
                    
                    const { color, icon, label } = getProviderRank(
                        info.getValue() as EAPIProviderRanks
                    );
                    return <Badge color={color} icon={icon} label={label} size="sm" />;
                },
                header: "New Deal Rating",
                id: `atc_new_deal_rating`,
                size: 250,
            }),
            columnHelper.accessor("atc_new_price", {
                cell: (info) => defaultValueToDash(info.getValue()),
                header: "New Price",
                id: `atc_new_price`,
            }),
            columnHelper.accessor("atc_price_change", {
                cell: (info) => defaultValueToDash(info.getValue()),
                header: "Price Change",
                id: `atc_price_change`,
            }),
            columnHelper.accessor("atc_great_deal_rating", {
                cell: (info) => {

                    if (!info.getValue()) {
                        return '-';
                    }

                    const { color, icon, label } = getProviderRank(
                        info.getValue() as EAPIProviderRanks
                    );
                    return <Badge color={color} icon={icon} label={label} size="sm" />;
                },
                header: "Great Deal Rating",
                id: `atc_great_deal_rating`,
                size: 250,
            }),
            columnHelper.accessor("atc_price_at_great_deal_rating", {
                cell: (info) => defaultValueToDash(info.getValue()),
                header: "Price at Great Deal Rating",
                id: `atc_price_at_great_deal_rating`,
            }),
            columnHelper.accessor("atc_price_change_at_great_deal_rating", {
                cell: (info) => defaultValueToDash(info.getValue()),
                header: "Price Change at Great Deal Rating",
                id: `atc_price_change_at_great_deal_rating`,
            }),
        ],
    },
    {
        header: "Cars.com",
        filterColumnId: EProviderInitials.CarsDotCom,
        // columns: getProviderColumns("carsDotCom", "cars_com"),
        columns: [
            columnHelper.accessor("ccm_price", {
                cell: (info) => defaultValueToDash(info.getValue()),
                header: "Price",
                id: `ccm_price`,
            }),
            columnHelper.accessor("ccm_deal_rating", {
                cell: (info) => {

                    if (!info.getValue()) {
                        return '-';
                    }

                    const { color, icon, label } = getProviderRank(
                        info.getValue() as EAPIProviderRanks
                    );
                    return <Badge color={color} icon={icon} label={label} size="sm" />;
                },
                header: "Deal Rating",
                id: `ccm_deal_rating`,
                size: 250,
            }),
            columnHelper.accessor("ccm_new_deal_rating", {
                cell: (info) => {

                    if (!info.getValue()) {
                        return '-';
                    }

                    const { color, icon, label } = getProviderRank(
                        info.getValue() as EAPIProviderRanks
                    );
                    return <Badge color={color} icon={icon} label={label} size="sm" />;
                },
                header: "New Deal Rating",
                id: `ccm_new_deal_rating`,
                size: 250,
            }),
            columnHelper.accessor("ccm_new_price", {
                cell: (info) => defaultValueToDash(info.getValue()),
                header: "New Price",
                id: `ccm_new_price`,
            }),
            columnHelper.accessor("ccm_price_change", {
                cell: (info) => defaultValueToDash(info.getValue()),
                header: "Price Change",
                id: `ccm_price_change`,
            }),
            columnHelper.accessor("ccm_great_deal_rating", {
                cell: (info) => {

                    if (!info.getValue()) {
                        return '-';
                    }
                    
                    const { color, icon, label } = getProviderRank(
                        info.getValue() as EAPIProviderRanks
                    );
                    return <Badge color={color} icon={icon} label={label} size="sm" />;
                },
                header: "Great Deal Rating",
                id: `ccm_great_deal_rating`,
                size: 250,
            }),
            columnHelper.accessor("ccm_price_at_great_deal_rating", {
                cell: (info) => defaultValueToDash(info.getValue()),
                header: "Price at Great Deal Rating",
                id: `ccm_price_at_great_deal_rating`,
            }),
            columnHelper.accessor("ccm_price_change_at_great_deal_rating", {
                cell: (info) => defaultValueToDash(info.getValue()),
                header: "Price Change at Great Deal Rating",
                id: `ccm_price_change_at_great_deal_rating`,
            }),
        ],
    },
    {
        header: "Carfax",
        filterColumnId: EProviderInitials.Carfax,
        // columns: getProviderColumns("carsDotCom", "cars_com"),
        columns: [
            columnHelper.accessor("cfx_price", {
                cell: (info) => defaultValueToDash(info.getValue()),
                header: "Price",
                id: `cfx_price`,
            }),
            columnHelper.accessor("cfx_deal_rating", {
                cell: (info) => {

                    if (!info.getValue()) {
                        return '-';
                    }

                    const { color, icon, label } = getProviderRank(
                        info.getValue() as EAPIProviderRanks
                    );
                    return <Badge color={color} icon={icon} label={label} size="sm" />;
                },
                header: "Deal Rating",
                id: `cfx_deal_rating`,
                size: 250,
            }),
            columnHelper.accessor("cfx_new_deal_rating", {
                cell: (info) => {

                    if (!info.getValue()) {
                        return '-';
                    }

                    const { color, icon, label } = getProviderRank(
                        info.getValue() as EAPIProviderRanks
                    );
                    return <Badge color={color} icon={icon} label={label} size="sm" />;
                },
                header: "New Deal Rating",
                id: `cfx_new_deal_rating`,
                size: 250,
            }),
            columnHelper.accessor("cfx_new_price", {
                cell: (info) => defaultValueToDash(info.getValue()),
                header: "New Price",
                id: `cfx_new_price`,
            }),
            columnHelper.accessor("cfx_price_change", {
                cell: (info) => defaultValueToDash(info.getValue()),
                header: "Price Change",
                id: `cfx_price_change`,
            }),
            columnHelper.accessor("cfx_great_deal_rating", {
                cell: (info) => {

                    if (!info.getValue()) {
                        return '-';
                    }
                    
                    const { color, icon, label } = getProviderRank(
                        info.getValue() as EAPIProviderRanks
                    );
                    return <Badge color={color} icon={icon} label={label} size="sm" />;
                },
                header: "Great Deal Rating",
                id: `cfx_great_deal_rating`,
                size: 250,
            }),
            columnHelper.accessor("cfx_price_at_great_deal_rating", {
                cell: (info) => defaultValueToDash(info.getValue()),
                header: "Price at Great Deal Rating",
                id: `cfx_price_at_great_deal_rating`,
            }),
            columnHelper.accessor("cfx_price_change_at_great_deal_rating", {
                cell: (info) => defaultValueToDash(info.getValue()),
                header: "Price Change at Great Deal Rating",
                id: `cfx_price_change_at_great_deal_rating`,
            }),
        ],
    },
];

export const getXLSColumns = (
    inventory: TInventoryOptionValue,
    columnVisibility: VisibilityState
): TAggregatorColumnXLS<TTipReportV2Results>[] => {

    const columns: TAggregatorColumnXLS<TTipReportV2Results>[] = [
        {
            label: "Vehicle",
            key: "description" as any,
            colspan: 4,
            columns: [
                { label: "VIN", key: "vin" },
                { 
                    label: "Stock #", 
                    key: "stock_number",
                    func(value, row) {
                        return defaultValueToDash(value);
                    }
                },
                { label: "Vehicle", key: "vehicle" },
                { label: "Age", key: "age" },
            ],
        },
        {
            label: "CarGurus",
            key: "cgr" as any,
            filterColumnId: EProviderInitials.CarGurus,
            colspan: 5,
            columns: [
                {
                    label: "Deal Rating",
                    key: "cgr_deal_rating",
                    func(value, row) {
                        return EAPIProviderRanksReverse[value as EAPIProviderRanks];
                    },
                },
                {
                    label: "Price",
                    key: "cgr_price",
                },
                {
                    label: "New Deal Rating",
                    key: "cgr_new_deal_rating",
                    func(value, row) {
                        return EAPIProviderRanksReverse[value as EAPIProviderRanks];
                    },
                },
                {
                    label: "New Price",
                    key: "cgr_new_price",
                },
                {
                    label: "Price Change",
                    key: "cgr_price_change",
                },
            ],
        },
        {
            label: "Autotrader",
            key: "atc" as any,
            filterColumnId: EProviderInitials.Autotrader,
            colspan: 8,
            columns: [
                {
                    label: "Deal Rating",
                    key: "atc_deal_rating",
                    func(value, row) {
                        return EAPIProviderRanksReverse[value as EAPIProviderRanks];
                    },
                },
                {
                    label: "Price",
                    key: "atc_price"
                },
                {
                    label: "New Deal Rating",
                    key: "atc_new_deal_rating",
                    func(value, row) {
                        return EAPIProviderRanksReverse[value as EAPIProviderRanks];
                    },
                },
                {
                    label: "New Price",
                    key: "atc_new_price"
                },
                {
                    label: "Price Change",
                    key: "atc_price_change"
                },
                {
                    label: "Great Deal Rating",
                    key: "atc_great_deal_rating",
                    func(value, row) {
                        return EAPIProviderRanksReverse[value as EAPIProviderRanks];
                    },
                },
                {
                    label: "Price at Great Deal Rating",
                    key: "atc_price_at_great_deal_rating"
                },
                {
                    label: "Price Change at Great Deal Rating",
                    key: "atc_price_change_at_great_deal_rating"
                }
            ],
        },
        {
            label: "Cars.com",
            key: "ccm" as any,
            filterColumnId: EProviderInitials.CarsDotCom,
            colspan: 8,
            columns: [
                {
                    label: "Deal Rating",
                    key: "ccm_deal_rating",
                    func(value, row) {
                        return EAPIProviderRanksReverse[value as EAPIProviderRanks];
                    },
                },
                {
                    label: "Price",
                    key: "ccm_price"
                },
                {
                    label: "New Deal Rating",
                    key: "ccm_new_deal_rating",
                    func(value, row) {
                        return EAPIProviderRanksReverse[value as EAPIProviderRanks];
                    },
                },
                {
                    label: "New Price",
                    key: "ccm_new_price"
                },
                {
                    label: "Price Change",
                    key: "ccm_price_change"
                },
                {
                    label: "Great Deal Rating",
                    key: "ccm_great_deal_rating",
                    func(value, row) {
                        return EAPIProviderRanksReverse[value as EAPIProviderRanks];
                    },
                },
                {
                    label: "Price at Great Deal Rating",
                    key: "ccm_price_at_great_deal_rating"
                },
                {
                    label: "Price Change at Great Deal Rating",
                    key: "ccm_price_change_at_great_deal_rating"
                }
            ],
        },
        // carfax
        {
            label: "Carfax",
            key: "cfx" as any,
            filterColumnId: EProviderInitials.Carfax,
            colspan: 8,
            columns: [
                {
                    label: "Deal Rating",
                    key: "cfx_deal_rating",
                    func(value, row) {
                        return EAPIProviderRanksReverse[value as EAPIProviderRanks];
                    },
                },
                {
                    label: "Price",
                    key: "cfx_price"
                },
                {
                    label: "New Deal Rating",
                    key: "cfx_new_deal_rating",
                    func(value, row) {
                        return EAPIProviderRanksReverse[value as EAPIProviderRanks];
                    },
                },
                {
                    label: "New Price",
                    key: "cfx_new_price"
                },
                {
                    label: "Price Change",
                    key: "cfx_price_change"
                },
                {
                    label: "Great Deal Rating",
                    key: "cfx_great_deal_rating",
                    func(value, row) {
                        return EAPIProviderRanksReverse[value as EAPIProviderRanks];
                    },
                },
                {
                    label: "Price at Great Deal Rating",
                    key: "cfx_price_at_great_deal_rating"
                },
                {
                    label: "Price Change at Great Deal Rating",
                    key: "cfx_price_change_at_great_deal_rating"
                }
            ],
        },
    ];

    return columns.map(column => {
        if (column.columns) {
            column.columns = column.columns.filter(
                nestedColumn => columnVisibility[nestedColumn.key] !== false
            );
            column.colspan = column.columns.length; // Update colspan based on visible nested columns
        }
        return column;
    }).filter(column => columnVisibility[column.key] !== false); 

};

export const getPdfColumns = (
    inventory: TInventoryOptionValue,
    columnVisibility: VisibilityState
): TAggregatorColumnPDF<TTipReportV2Results>[] => {
    const columns: TAggregatorColumnPDF<TTipReportV2Results>[] = [
        {
            key: 'vin',
            label: 'VIN',
        },
        {
            key: 'stock_number',
            label: 'Stock #',
            func(value, row) {
                return defaultValueToDash(value);
            }
        },
        {
            key: 'vehicle',
            label: 'Vehicle',
        },
        {
            key: 'age',
            label: 'Age',
        },

        {
            key: 'cgr_deal_rating',
            label: 'Deal Rating',
            filterColumnId: EProviderInitials.CarGurus,
            func(value) {
                return EAPIProviderRanksReverse[value as EAPIProviderRanks];
            }
        },
        {
            key: 'cgr_price',
            label: 'Price',
            filterColumnId: EProviderInitials.CarGurus,
        },
        {
            key: 'cgr_new_deal_rating',
            label: 'New Deal Rating',
            filterColumnId: EProviderInitials.CarGurus,
            func(value) {
                return EAPIProviderRanksReverse[value as EAPIProviderRanks];
            }
        },
        {
            key: 'cgr_new_price',
            label: 'New Price',
            filterColumnId: EProviderInitials.CarGurus,
        },
        {
            key: 'cgr_price_change',
            label: 'Price Change',
            filterColumnId: EProviderInitials.CarGurus,
        },
        {
            key: 'atc_deal_rating',
            label: 'Deal Rating',
            filterColumnId: EProviderInitials.Autotrader,
            func(value) {
                return EAPIProviderRanksReverse[value as EAPIProviderRanks];
            }
        },
        {
            key: 'atc_price',
            label: 'Price',
            filterColumnId: EProviderInitials.Autotrader,
        },
        {
            key: 'atc_new_deal_rating',
            label: 'New Deal Rating',
            filterColumnId: EProviderInitials.Autotrader,
            func(value) {
                return EAPIProviderRanksReverse[value as EAPIProviderRanks];
            }
        },
        {
            key: 'atc_new_price',
            label: 'New Price',
            filterColumnId: EProviderInitials.Autotrader,
        },
        {
            key: 'atc_price_change',
            label: 'Price Change',
            filterColumnId: EProviderInitials.Autotrader,
        },
        {
            key: 'atc_great_deal_rating',
            label: 'Great Deal Rating',
            filterColumnId: EProviderInitials.Autotrader,
            func(value) {
                return EAPIProviderRanksReverse[value as EAPIProviderRanks];
            }
        },
        {
            key: 'atc_price_at_great_deal_rating',
            label: 'Price at Great Deal Rating',
            filterColumnId: EProviderInitials.Autotrader,
        },
        {
            key: 'atc_price_change_at_great_deal_rating',
            label: 'Price Change at Great Deal Rating',
            filterColumnId: EProviderInitials.Autotrader,
        },

        {
            key: 'ccm_deal_rating',
            label: 'Deal Rating',
            filterColumnId: EProviderInitials.CarsDotCom,
            func(value) {
                return EAPIProviderRanksReverse[value as EAPIProviderRanks];
            }
        },
        {
            key: 'ccm_price',
            label: 'Price',
            filterColumnId: EProviderInitials.CarsDotCom,
        },
        {
            key: 'ccm_new_deal_rating',
            label: 'New Deal Rating',
            filterColumnId: EProviderInitials.CarsDotCom,
            func(value) {
                return EAPIProviderRanksReverse[value as EAPIProviderRanks];
            }
        },
        {
            key: 'ccm_new_price',
            label: 'New Price',
            filterColumnId: EProviderInitials.CarsDotCom,
        },
        {
            key: 'ccm_price_change',
            label: 'Price Change',
            filterColumnId: EProviderInitials.CarsDotCom,
        },
        {
            key: 'ccm_great_deal_rating',
            label: 'Great Deal Rating',
            filterColumnId: EProviderInitials.CarsDotCom,
            func(value) {
                return EAPIProviderRanksReverse[value as EAPIProviderRanks];
            }
        },
        {
            key: 'ccm_price_at_great_deal_rating',
            label: 'Price at Great Deal Rating',
            filterColumnId: EProviderInitials.CarsDotCom,
        },
        {
            key: 'ccm_price_change_at_great_deal_rating',
            label: 'Price Change at Great Deal Rating',
            filterColumnId: EProviderInitials.CarsDotCom,
        },

        // cfx
        {
            key: 'cfx_deal_rating',
            label: 'Deal Rating',
            filterColumnId: EProviderInitials.Carfax,
            func(value) {
                return EAPIProviderRanksReverse[value as EAPIProviderRanks];
            }
        },
        {
            key: 'cfx_price',
            label: 'Price',
            filterColumnId: EProviderInitials.Carfax,
        },
        {
            key: 'cfx_new_deal_rating',
            label: 'New Deal Rating',
            filterColumnId: EProviderInitials.Carfax,
            func(value) {
                return EAPIProviderRanksReverse[value as EAPIProviderRanks];
            }
        },
        {
            key: 'cfx_new_price',
            label: 'New Price',
            filterColumnId: EProviderInitials.Carfax,
        },
        {
            key: 'cfx_price_change',
            label: 'Price Change',
            filterColumnId: EProviderInitials.Carfax,
        },
        {
            key: 'cfx_great_deal_rating',
            label: 'Great Deal Rating',
            filterColumnId: EProviderInitials.Carfax,
            func(value) {
                return EAPIProviderRanksReverse[value as EAPIProviderRanks];
            }
        },
        {
            key: 'cfx_price_at_great_deal_rating',
            label: 'Price at Great Deal Rating',
            filterColumnId: EProviderInitials.Carfax,
        },
        {
            key: 'cfx_price_change_at_great_deal_rating',
            label: 'Price Change at Great Deal Rating',
            filterColumnId: EProviderInitials.Carfax,
        },
    ];

    return columns.filter(column => columnVisibility[column.key] !== false); 

};

