import { createColumnHelper, VisibilityState } from "@tanstack/react-table";
import { TDealer } from "api/dealershipApi";
import { EOrderingReports, TAdvancedReportResultsV2 } from "api/useGetStatistics";
import Badge from "components/Badge";
import { TInventoryOptionValue } from "constants/common";
import { EAPIProviderRanks, EAPIProviderRanksReverse, EProviderInitials } from "types/APIProviderRanks";
// import {
//   EProviderInitials,
//   IPairProviderRatings,
// } from "types/APIProviderRanks";
import TAggregatorColumnPDF from "types/TAggregatorColumnPDF";
import TAggregatorColumnXLS from "types/TAggregatorColumnXLS";
import getProviderRank from "utils/getProviderRank";
// import getDealRatingPair from "utils/getDealRatingPair";
// import getProviderRatingCell from "utils/getProviderRatingCell";
// import getProviderRatingCellPDF from "utils/getProviderRatingCellPDF";

const columnHelper = createColumnHelper<TAdvancedReportResultsV2>();

export const getColumns = (inventory: TInventoryOptionValue, dealer: TDealer) => ([
  columnHelper.accessor("vin", {
    cell: (info) => 
      info.getValue() === null
        ? "N/A"
        : info.getValue(),
    header: 'VIN',
    id: "vin",
    // default to unchecked
  }),
  columnHelper.accessor("stock_number", {
    cell: (info) => info.getValue() ?? '-',
    header: 'Stock #',
    id: "stock_number",
  }),
  columnHelper.accessor('vehicle',{
      cell: (info) => info.getValue(),
      header: 'Vehicle',
      id: "vehicle",
      size: 350,
    }
  ),
  ...(inventory === 'used' ? [
    {
      filterColumnId: EProviderInitials.CarGurus,
      ...columnHelper.accessor('cgr_deal_rating', {
          cell: (info) => {
            const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
            return <Badge color={color} icon={icon} label={label} size="sm" />;
          },
          header: 'CarGurus Rank',
          id: "cgr_deal_rating",
          size: 250,
        }
      ),
    },
    {
      filterColumnId: EProviderInitials.Autotrader,
      ...columnHelper.accessor('atc_deal_rating',{
          cell: (info) => {
            const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
            return <Badge color={color} icon={icon} label={label} size="sm" />;
          },
          header: 'Autotrader Rank',
          id: "atc_deal_rating",
          size: 250,
        }
      ),
    },
    {
      filterColumnId: EProviderInitials.CarsDotCom,
      ...columnHelper.accessor('ccm_deal_rating', {
          cell: (info) => {
            const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
            return <Badge color={color} icon={icon} label={label} size="sm" />;
          },
          header: 'Cars.com Rank',
          id: "ccm_deal_rating",
          size: 250,
        }
      ),
    },
    {
      filterColumnId: EProviderInitials.Carfax,
      ...columnHelper.accessor('cfx_deal_rating', {
          cell: (info) => {
            const { color, icon, label } = getProviderRank(info.getValue() as EAPIProviderRanks);
            return <Badge color={color} icon={icon} label={label} size="sm" />;
          },
          header: 'Carfax Rank',
          id: "cfx_deal_rating",
          size: 250,
        }
      ),
    },
  ] : []),
  columnHelper.accessor("price", {
    cell: (info) => `${info.getValue()}`,
    header: 'Price',
    id: "price",
    size: 150,
  }),
  ...(inventory === 'used' ? [
    columnHelper.accessor("default_percentage_to_market", {
      cell: (info) =>
        info.getValue() === null
          ? "N/A"
          : `${(info.getValue() * 100).toLocaleString()}%`,
      header: 'Default % of Market',
      id: "default_percentage_to_market",
      size: 250,
    }),
  ] : []),
  ...(inventory === 'new' ? [
    columnHelper.accessor("price_to_market", {
      cell: (info) =>
        info.getValue() === null
          ? "N/A"
          : `${(info.getValue() * 100).toLocaleString()}%`,
      header: 'Price to Market',
      id: "percentage_to_market",
      size: 250,
    }),
  ] : []),
  columnHelper.accessor("dol", {
    cell: (info) => info.getValue(),
    header: 'DOL',
    id: "dol",
    size: 150,
  }),
  columnHelper.accessor("photos", {
    cell: (info) => info.getValue().toLocaleString(),
    header: 'Photos',
    id: "photos",
    size: 150,
  }),
  columnHelper.accessor("total_leads", {
    cell: (info) => info.getValue().toLocaleString(),
    header: 'Leads',
    id: "total_leads",
    size: 150,
  }),
  columnHelper.accessor("total_vdps", {
    cell: (info) => info.getValue().toLocaleString(),
    header: 'VDPs',
    id: "total_vdps",
    size: 150,
  }),
  columnHelper.accessor("leads_vdps", {
    cell: (info) => info.getValue(),
    header: 'Leads/VDP',
    id: "leads_vdps",
    size: 200,
  }),
  ...(inventory === 'used' && dealer.is_carfax ? [
  columnHelper.accessor("carfax_pulls", {
    cell: (info) => info.getValue() || 0,
    header: 'Carfax Pulls',
    id: "carfax_pulls",
    size: 200,
  }),
  ] : [])
]);

export const getPdfColumns = (inventory: TInventoryOptionValue, dealer: TDealer, columnVisibility: VisibilityState): TAggregatorColumnPDF<TAdvancedReportResultsV2>[] => {
  const columns: TAggregatorColumnPDF<TAdvancedReportResultsV2>[] = [
    {
      label: "VIN",
      key: "vin",
      func(value): any {
        return value || "-";
      }
    },
    {
      label: "Stock #",
      key: "stock_number",
      func(value): any {
        return value || "-";
      }
    },
    {
      label: "Vehicle",
      key: "vehicle",
      func(value): any {
        return value || "-";
      }
    },
    ...(inventory === 'used' ? [
      {
        label: "CarGurus Rank",
        key: "cgr_deal_rating",
        func(value) {
          return EAPIProviderRanksReverse[value as EAPIProviderRanks];
        }
      },
      {
        label: "Autotrader Rank",
        key: "atc_deal_rating",
        func(value) {
          return EAPIProviderRanksReverse[value as EAPIProviderRanks];
        }
      },
      {
        label: "Cars.com Rank",
        key: "ccm_deal_rating",
        func(value) {
          return EAPIProviderRanksReverse[value as EAPIProviderRanks];
        }
      },
      {
        label: "Carfax Rank",
        key: "cfx_deal_rating",
        func(value) {
          console.log('cfx_deal_rating', value);
          return EAPIProviderRanksReverse[value as EAPIProviderRanks];
        }
      },
    ] : []) as TAggregatorColumnPDF<TAdvancedReportResultsV2>[],
    {
      label: "Price",
      key: "price",
      func(value): any {
        return value || "-";
      }
    },
    ...(inventory === 'used' ? [
      {
        label: "Default % of Market",
        key: "default_percentage_to_market",
        func: (value) =>
          value === null
            ? "N/A"
            : `${(((value as number) || 0) * 100).toLocaleString()}%`,
      },
    ] : []) as TAggregatorColumnPDF<TAdvancedReportResultsV2>[],
    ...(inventory === 'new' ? [
      {
        label: "Price to Market",
        key: "price_to_market",
        func: (value) =>
          value === null
            ? "N/A"
            : `${(((value as number) || 0) * 100).toLocaleString()}%`,
      },
    ] : []) as TAggregatorColumnPDF<TAdvancedReportResultsV2>[],
    {
      label: "DOL",
      key: "dol",
      func(value): any {
        return value || "-";
      }
    },
    {
      label: "Photos",
      key: "photos",
      func(value): any {
        return value || "-";
      }
    },
    {
      label: "Leads",
      key: "total_leads",
      func(value): any {
        return value || "-";
      }
      // func: (value) => value.toLocaleString(),
    },
    {
      label: "VDPs",
      key: "total_vdps",
      func(value): any {
        return value || "-";
      }
      // func: (value) => value.toLocaleString(),
    },
    {
      label: "Leads/VDP",
      key: "leads_vdps",
      func(value): any {
        return value || "-";
      }
      // func: (value) => value.toLocaleString(),
    },
    ...(inventory === 'used' && dealer.is_carfax ? [
      {
        label: "Carfax Pulls",
        key: "carfax_pulls",
        // func: (value) => value?.toLocaleString() || 0,
      },
    ] : []) as TAggregatorColumnPDF<TAdvancedReportResultsV2>[],
  ];

  return columns.filter(column => columnVisibility[column.key] !== false); 
};

export const getXLSColumns = (inventory: TInventoryOptionValue, dealer: TDealer, columnVisibility: VisibilityState): TAggregatorColumnXLS<TAdvancedReportResultsV2>[] => {
  const columns: TAggregatorColumnXLS<TAdvancedReportResultsV2>[] = [
    {
      label: "VIN",
      key: "vin",
      func(value): any {
        return value || "-";
      }
    },
    {
      label: "Stock #",
      key: "stock_number",
      func(value): any {
        return value || "-";
      }
    },
    {
      label: "Vehicle",
      key: "vehicle",
      func(value): any {
        return value || "-";
      }
    },
    ...(inventory === 'used' ? [
      {
        label: "CarGurus Rank",
        key: "cgr_deal_rating",
        func(value) {
          return EAPIProviderRanksReverse[value as EAPIProviderRanks];
        }
      },
      {
        label: "Autotrader Rank",
        key: "atc_deal_rating",
        func(value) {
          return EAPIProviderRanksReverse[value as EAPIProviderRanks];
        }
      },
      {
        label: "Cars.com Rank",
        key: "ccm_deal_rating",
        func(value) {
          return EAPIProviderRanksReverse[value as EAPIProviderRanks];
        }
      },
      {
        label: "Carfax Rank",
        key: "cfx_deal_rating",
        func(value) {
          return EAPIProviderRanksReverse[value as EAPIProviderRanks];
        }
      },
    ] : []) as TAggregatorColumnXLS<TAdvancedReportResultsV2>[],
    {
      label: "Price",
      key: "price",
      func(value): any {
        return value || "-";
      }
    },
    ...(inventory === 'used' ? [
      {
        label: "Default % of Market",
        key: "default_percentage_to_market",
        func: (value) =>
          value === null
            ? "N/A"
            : `${(((value as number) || 0) * 100).toLocaleString()}%`,
      },
    ] : []) as TAggregatorColumnXLS<TAdvancedReportResultsV2>[],
    ...(inventory === 'new' ? [
      {
        label: "Price to Market",
        key: "price_to_market",
        func: (value) =>
          value === null
            ? "N/A"
            : `${(((value as number) || 0) * 100).toLocaleString()}%`,
      },
    ] : []) as TAggregatorColumnXLS<TAdvancedReportResultsV2>[],
    {
      label: "DOL",
      key: "dol",
      func(value): any {
        return value || "-";
      }
    },
    {
      label: "Photos",
      key: "photos",
      func(value): any {
        return value || "-";
      }
    },
    {
      label: "Leads",
      key: "total_leads",
      func(value): any {
        return value || "-";
      }
      // func: (value) => value.toLocaleString(),
    },
    {
      label: "VDPs",
      key: "total_vdps",
      func(value): any {
        return value || "-";
      }
      // func: (value) => value.toLocaleString(),
    },
    {
      label: "Leads/VDP",
      key: "leads_vdps",
      func(value): any {
        return value || "-";
      }
      // func: (value) => value.toLocaleString(),
    },
    ...(inventory === 'used' && dealer.is_carfax ? [
      {
        label: "Carfax Pulls",
        key: "carfax_pulls",
        func(value): any {
          return value || "-";
        }
        // func: (value) => value?.toLocaleString() || 0,
      },
    ] : []) as TAggregatorColumnXLS<TAdvancedReportResultsV2>[],
  ];

  return columns.filter(column => columnVisibility[column.key] !== false); 

};

export const tableTabs = [
  { id: EOrderingReports.MostLeads, label: "Leads" },
  { id: EOrderingReports.MostVDPs, label: "Most VDPs" },
  { id: EOrderingReports.LeastVDPs, label: "Least VDPs (10+ DOL)" },
  { id: EOrderingReports.DaysOnLot, label: "Days on Lot" },
  { id: EOrderingReports.VDPBoost, label: "VDP Boost" },
];

export const tablePDFFileNames = {
  [EOrderingReports.MostLeads]: "leads",
  [EOrderingReports.MostVDPs]: "most-vdps",
  [EOrderingReports.LeastVDPs]: "least-vdps",
  [EOrderingReports.DaysOnLot]: "days-on-lot",
  [EOrderingReports.VDPBoost]: "vdp-boost",
};

export const defaultOffset = 0;
