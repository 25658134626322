import { FC, useCallback, useState } from "react";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import { ReactComponent as MailIcon } from "assets/icons/mail.svg";
import { ReactComponent as PrintIcon } from "assets/icons/printer.svg";
import Button from "components/Button";
import styles from "../../styles.module.scss";
import { IActionButtonsProps } from "./types";
import {
  generateDownloadPdf,
  generateEmailPdf,
  generatePrintPdf,
  generateDownloadXLS,
  generateEmailXLS,
} from "./utils";
import { Box, Button as ChakraButton, Menu, MenuButton, MenuGroup, MenuItem, MenuList, Spinner } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon, EmailIcon } from "@chakra-ui/icons";
import { IProviderCheckList } from "types/IProviderCheckList";

const ActionButtons: FC<IActionButtonsProps> = ({
  onGeneratePDF,
  onGenerateXLS,
}) => {
  const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);
  const [isLoadingPrint, setIsLoadingPrint] = useState<boolean>(false);
  const [isLoadingEmail, setIsLoadingEmail] = useState<boolean>(false);

  const onClickDownload = useCallback(() => {
    if (onGeneratePDF) {
      onGeneratePDF(generateDownloadPdf, setIsLoadingDownload);
    } else if (onGenerateXLS) {
      onGenerateXLS(generateDownloadXLS, setIsLoadingDownload);
    }
  }, [onGeneratePDF, onGenerateXLS]);

  const onClickPrint = useCallback(() => {
    if (onGeneratePDF) {
      onGeneratePDF(generatePrintPdf, setIsLoadingPrint);
    }
  }, [onGeneratePDF]);

  const onClickEmail = useCallback(() => {
    if (onGeneratePDF) {
      onGeneratePDF(generateEmailPdf, setIsLoadingEmail);
    } else if (onGenerateXLS) {
      onGenerateXLS(generateEmailXLS, setIsLoadingEmail);
    }
  }, [onGeneratePDF, onGenerateXLS]);

  return (
    <div className={styles.actionButtons}>
      <Button
        disabled={isLoadingDownload}
        variant="secondary"
        onClick={onClickDownload}
      >
        {isLoadingDownload ? "Loading...." : "Download"}
        <DownloadIcon className={styles.downloadIcon} />
      </Button>
      {!onGenerateXLS && onGeneratePDF && (
        <Button
          disabled={isLoadingPrint}
          variant="secondary"
          onClick={onClickPrint}
        >
          {isLoadingPrint ? "Loading..." : "Print"}
          <PrintIcon className={styles.printIcon} />
        </Button>
      )}
      <Button
        disabled={isLoadingEmail}
        variant="secondary"
        onClick={onClickEmail}
      >
        {isLoadingEmail ? "Loading..." : "Email"}
        <MailIcon className={styles.mailIcon} />
      </Button>
    </div>
  );
};

export default ActionButtons;


export type ActionButtonV2Props = {
  providers?: IProviderCheckList[];
  actionConfig?: {
    isDownloadXLS?: boolean;
    isDownloadPDF?: boolean;
    isProviderPrint?: boolean;
    isPrintPDF?: boolean;
    isEmailPDF?: boolean;
    isEmailXLS?: boolean;
  }
} & IActionButtonsProps;

export const ActionButtonV2: React.FC<ActionButtonV2Props> = ({
  onGeneratePDF,
  onDownloadPDF,
  onDownloadXLS,
  actionConfig = {
    isDownloadPDF: false,
    isDownloadXLS: true,
    isPrintPDF: true,
    isProviderPrint: false,
    isEmailXLS: true,
    isEmailPDF: false
  },
  providers
}) => {

  const [isLoadingDownloadXSL, setIsLoadingDownloadXSL] = useState<boolean>(false);
  const [isLoadingDownloadPDF, setIsLoadingDownloadPDF] = useState<boolean>(false);
  const [isLoadingEmail, setIsLoadingEmail] = useState<boolean>(false);
  const [isLoadingPrint, setIsLoadingPrint] = useState<boolean>(false);

  const onClickEmailPDF = useCallback((provider?: IProviderCheckList) => {

    if (!onGeneratePDF) return;

    onGeneratePDF(generateEmailPdf, setIsLoadingEmail, provider);

  }, [onGeneratePDF]);

  const onClickEmailXLS = useCallback(() => {
    if (!onDownloadXLS) return;

    onDownloadXLS(generateEmailXLS, setIsLoadingEmail);
  }, [onDownloadXLS]);

  const onClickDownloadXLS = useCallback(() => {
    if (!onDownloadXLS) return;

      onDownloadXLS(generateDownloadXLS, setIsLoadingDownloadXSL);
      
  }, [onDownloadXLS])

  const onClickDownloadPDF = useCallback((provider?: IProviderCheckList) => {

    if (!onGeneratePDF) return;
    
    onGeneratePDF(generateDownloadPdf, setIsLoadingDownloadPDF, provider);

  }, [onGeneratePDF]);
  

  const onClickPrint = useCallback((provider?: IProviderCheckList) => {
    if (!onGeneratePDF) return;
    
    if (actionConfig.isEmailPDF) {
      onGeneratePDF(generatePrintPdf, setIsLoadingPrint, provider);
    } else if (actionConfig.isEmailXLS) {
      onGeneratePDF(generatePrintPdf, setIsLoadingPrint);
    }

  }, [actionConfig.isEmailPDF, actionConfig.isEmailXLS, onGeneratePDF]);

  const getDownloadButton = () => {
    if (!actionConfig) return;
    
    if (actionConfig.isDownloadPDF && !actionConfig.isDownloadXLS) {
      return (
        <ChakraButton
          rightIcon={<DownloadIcon color="#8bb9a8" fontSize="large" />}
          size="md"
          border="1px solid #b3b8db"
          backgroundColor="#ffffff"
          fontSize="14px"
          padding="10px 16px"
          // height="43px"
          onClick={() => onClickDownloadPDF(undefined)}
          isLoading={isLoadingDownloadPDF}
          loadingText="Download"
          spinnerPlacement="end"
        >
          Download
        </ChakraButton>
      )
    } else if (!actionConfig.isDownloadPDF && actionConfig.isDownloadXLS) {
      return (
        <ChakraButton
          rightIcon={<DownloadIcon color="#8bb9a8" fontSize="large" />}
          size="md"
          border="1px solid #b3b8db"
          backgroundColor="#ffffff"
          fontSize="14px"
          padding="10px 16px"
          // height="43px"
          onClick={() => onClickDownloadXLS()}
          isLoading={isLoadingDownloadXSL}
          loadingText="Download"
          spinnerPlacement="end"
        >
          Download
        </ChakraButton>
      )
    } else if (actionConfig.isDownloadPDF && actionConfig.isDownloadXLS) {
      return (
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton
                padding="10px 16px"
                transition="all 0.2s"
                borderRadius="md"
                border="1px solid #b3b8db"
                backgroundColor="#ffffff"
                fontSize="14px"
                fontWeight="var(--chakra-fontWeights-semibold)"
                disabled={isLoadingDownloadPDF || isLoadingDownloadXSL}
                {...(isLoadingDownloadPDF || isLoadingDownloadXSL
                  ? {
                      opacity: "0.4",
                      cursor: "not-allowed",
                      color: "var(--chakra-colors-gray-400)",
                      _hover: {
                        backgroundColor: "var(--chakra-colors-gray-100)",
                      },
                    }
                  : {
                      _hover: {
                        backgroundColor: "var(--chakra-colors-gray-200)",
                      },
                    })}
                {...(isOpen && {
                  backgroundColor: "var(--chakra-colors-gray-200)",
                })}
              >
                <Box display="flex" gap="0.5rem" alignItems="center">
                  Download
                  {isLoadingDownloadPDF || isLoadingDownloadXSL ? (
                    <Spinner width="14px" height="14px" />
                  ) : isOpen ? (
                    <ChevronUpIcon color="#8bb9a8" fontSize="large" />
                  ) : (
                    <ChevronDownIcon color="#8bb9a8" fontSize="large" />
                  )}
                </Box>
              </MenuButton>
              <MenuList
                zIndex={2}
              >
                {providers && (
                  <>
                  <MenuGroup title='Excel'>
                    <MenuItem
                      icon={<DownloadIcon color="#8bb9a8" fontSize="large" />}
                      onClick={onClickDownloadXLS}
                      fontSize="14px"
                    >
                      All Providers
                    </MenuItem>
                  </MenuGroup>
                  <MenuGroup title='PDF'>
                    {providers.filter((provider) => provider.value === true).map((provider) => (
                      <MenuItem 
                        key={provider.id}
                        icon={<DownloadIcon color="#8bb9a8" fontSize="large" />}
                        onClick={() => onClickDownloadPDF(provider)}
                        fontSize="14px"
                      >
                        {provider.label}
                      </MenuItem>
                    ))}
                  </MenuGroup>
                  </>
                )}
                {!providers && (
                  <>
                    <MenuItem
                      icon={<DownloadIcon color="#8bb9a8" fontSize="large" />}
                      onClick={onClickDownloadXLS}
                      fontSize="14px"
                    >
                      Excel
                    </MenuItem>
                      <MenuItem
                        icon={<DownloadIcon color="#8bb9a8" fontSize="large" />}
                        onClick={() => onClickDownloadPDF(undefined)}
                        fontSize="14px"
                      >
                        PDF
                      </MenuItem>
                  </>
                )}
              </MenuList>
            </>
          )}
        </Menu>
      )
    } else {
      return (
        <ChakraButton
          rightIcon={<DownloadIcon color="#8bb9a8" fontSize="large" />}
          size="md"
          border="1px solid #b3b8db"
          backgroundColor="#ffffff"
          fontSize="14px"
          padding="10px 16px"
          // height="43px"
          onClick={onClickDownloadXLS}
          isLoading={isLoadingDownloadXSL}
          loadingText="Download"
          spinnerPlacement="end"
        >
          Download
        </ChakraButton>
      );
    }
  }

  const getPrintButton = () => {
    if (!actionConfig) return;
    if (actionConfig.isPrintPDF && actionConfig.isProviderPrint) {
      return (
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton
                padding="10px 16px"
                transition="all 0.2s"
                borderRadius="md"
                border="1px solid #b3b8db"
                backgroundColor="#ffffff"
                fontSize="14px"
                fontWeight="var(--chakra-fontWeights-semibold)"
                disabled={isLoadingPrint}
                {...(isLoadingPrint
                  ? {
                      opacity: "0.4",
                      cursor: "not-allowed",
                      color: "var(--chakra-colors-gray-400)",
                      _hover: {
                        backgroundColor: "var(--chakra-colors-gray-100)",
                      },
                    }
                  : {
                      _hover: {
                        backgroundColor: "var(--chakra-colors-gray-200)",
                      },
                    })}
                {...(isOpen && {
                  backgroundColor: "var(--chakra-colors-gray-200)",
                })}
              >
                <Box display="flex" gap="0.5rem" alignItems="center">
                  Print
                  {isLoadingPrint ? (
                    <Spinner width="14px" height="14px" />
                  ) : isOpen ? (
                    <ChevronUpIcon color="#8bb9a8" fontSize="large" />
                  ) : (
                    <ChevronDownIcon color="#8bb9a8" fontSize="large" />
                  )}
                </Box>
              </MenuButton>
              {providers && (
                <MenuList
                  zIndex={2}
                >
                  <MenuGroup title='PDF'>
                    {providers.filter((provider) => provider.value === true).map((provider) => (
                      <MenuItem 
                        key={provider.id}
                        icon={<PrintIcon color="#8bb9a8" fontSize="large" />}
                        onClick={() => onClickPrint(provider)}
                        fontSize="14px"
                      >
                        {provider.label}
                      </MenuItem>
                    ))}
                  </MenuGroup>
                </MenuList>
              )}
            </>
          )}
        </Menu>
      );
    } else {
      return (
        <ChakraButton
          rightIcon={<PrintIcon color="#57adca" fontSize="large" />}
          size="md"
          border="1px solid #b3b8db"
          backgroundColor="#ffffff"
          fontSize="14px"
          padding="10px 16px"
          // height="43px"
          onClick={() => onClickPrint(undefined)}
          isLoading={isLoadingPrint}
          loadingText="Print"
          spinnerPlacement="end"
        >
          Print
        </ChakraButton>
      );
    }
  };

  const getEmailButton = () => {
    if (!actionConfig) return;
    if (actionConfig.isEmailPDF && !actionConfig.isEmailXLS) {
      return (
        <ChakraButton
          rightIcon={<EmailIcon color="#dcb972" fontSize="large" />}
          size="md"
          border="1px solid #b3b8db"
          backgroundColor="#ffffff"
          fontSize="14px"
          padding="10px 16px"
          // height="43px"
          onClick={() => onClickEmailPDF()}
          isLoading={isLoadingEmail}
          loadingText="Email"
          spinnerPlacement="end"
        >
          Email
        </ChakraButton>
      );
    } else if (actionConfig.isEmailPDF && actionConfig.isEmailXLS) {
      return (
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton
                padding="10px 16px"
                transition="all 0.2s"
                borderRadius="md"
                border="1px solid #b3b8db"
                backgroundColor="#ffffff"
                fontSize="14px"
                fontWeight="var(--chakra-fontWeights-semibold)"
                disabled={isLoadingEmail}
                {...(isLoadingEmail
                  ? {
                      opacity: "0.4",
                      cursor: "not-allowed",
                      color: "var(--chakra-colors-gray-400)",
                      _hover: {
                        backgroundColor: "var(--chakra-colors-gray-100)",
                      },
                    }
                  : {
                      _hover: {
                        backgroundColor: "var(--chakra-colors-gray-200)",
                      },
                    })}
                {...(isOpen && {
                  backgroundColor: "var(--chakra-colors-gray-200)",
                })}
              >
                <Box display="flex" gap="0.5rem" alignItems="center">
                  Email
                  {isLoadingEmail ? (
                    <Spinner width="14px" height="14px" />
                  ) : isOpen ? (
                    <ChevronUpIcon color="#8bb9a8" fontSize="large" />
                  ) : (
                    <ChevronDownIcon color="#8bb9a8" fontSize="large" />
                  )}
                </Box>
              </MenuButton>
              <MenuList
                zIndex={2}
              >
                {providers && (
                  <>
                  <MenuGroup title='Excel'>
                    <MenuItem
                      icon={<EmailIcon color="#8bb9a8" fontSize="large" />}
                      onClick={onClickEmailXLS}
                      fontSize="14px"
                    >
                      All Providers
                    </MenuItem>
                  </MenuGroup>
                  <MenuGroup title='PDF'>
                    {providers.filter((provider) => provider.value === true).map((provider) => (
                      <MenuItem 
                        key={provider.id}
                        icon={<EmailIcon color="#8bb9a8" fontSize="large" />}
                        onClick={() => onClickEmailPDF(provider)}
                        fontSize="14px"
                      >
                        {provider.label}
                      </MenuItem>
                    ))}
                  </MenuGroup>
                  </>
                )}

                {!providers && (
                  <>
                    <MenuItem
                      icon={<EmailIcon color="#8bb9a8" fontSize="large" />}
                      onClick={onClickEmailXLS}
                      fontSize="14px"
                    >
                      {'Excel'}
                    </MenuItem>
                    <MenuItem
                      icon={<EmailIcon color="#8bb9a8" fontSize="large" />}
                      onClick={() => onClickEmailPDF(undefined)}
                      fontSize="14px"
                    >
                      PDF
                    </MenuItem>
                  </>
                )}
              </MenuList>
            </>
          )}
        </Menu>
      );
    } else {
      return (
        <ChakraButton
          rightIcon={<EmailIcon color="#dcb972" fontSize="large" />}
          size="md"
          border="1px solid #b3b8db"
          backgroundColor="#ffffff"
          fontSize="14px"
          padding="10px 16px"
          // height="43px"
          onClick={() => onClickEmailXLS()}
          isLoading={isLoadingEmail}
          loadingText="Email"
          spinnerPlacement="end"
        >
          Email
        </ChakraButton>
      );
    }
  }

  return (
    <Box display="flex" alignItems="center" flex="row wrap" gap="10px">
      {getDownloadButton()}
      {getPrintButton()}
      {/* {getEmailButton()} */}
    </Box>
  );
};